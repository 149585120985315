import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Flag from "vue-flagpack";
import Editor from "@tinymce/tinymce-vue";
import "highlight.js/styles/mono-blue.css";
import VueApexCharts from "vue-apexcharts";
import "bootstrap/dist/js/bootstrap.min.js";
import VueHighlightJS from "vue-highlightjs";
import VuePageTransition from "vue-page-transition";
import VueCompositionAPI from "@vue/composition-api";
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import ToggleButton from 'vue-js-toggle-button'
import VCalendar from 'v-calendar';
import {initFirebaseBackend} from "./helpers/firebase/authUtils";
import moment from "moment";
import "moment/locale/pt-br";
import VueDraggable from 'vue-draggable'
import {isAcl, isAclArray} from "@/utils/validations";

Vue.use(VueDraggable)

Vue.use(ToggleButton)
Vue.use(Flag, {
    name: "gs-flag",
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VuePageTransition);
Vue.use(VueHighlightJS);
Vue.use(VueApexCharts);
Vue.use(VueCompositionAPI);

Vue.use(VCalendar, {
    componentPrefix: 'v',  // Use <vc-calendar />, <vc-date-picker />
    locales: {
        'en-US': {
            firstDayOfWeek: 0, // Define o domingo como o primeiro dia da semana
        },
        'pt-BR': {
            firstDayOfWeek: 0, // Define o domingo como o primeiro dia da semana
        }
    },
});

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPID,
    measurementId: process.env.VUE_APP_MEASUREMENTID,
};

initFirebaseBackend(firebaseConfig);

Vue.component("apexchart", VueApexCharts);
Vue.component("editor", Editor);


Vue.filter('acl', function (value) {
    if (value) {
        return isAcl(value);
    }
    return false;
})

Vue.filter('aclArray', function (value) {
    if (value) {
        return isAclArray(value);
    }
    return false;
})

Vue.filter('f_date', function (value) {
    if (value) {
        try {
            value = value.replace('T', ' ').split('.')[0];
        } catch (e) {
        }
        return moment(String(value)).format('DD/MM/YYYY')
    }
    return '';
})
Vue.filter('f_date_time_text', function (value) {
    if (value) {
        return moment(String(value)).format('D [de] MMMM [de] YYYY')
    }
    return '';
})
Vue.filter('f_date_2', function (value) {
    if (value) {
        try {
            value = value.replace('T', ' ').split('.')[0];
        } catch (e) {
        }
        return moment(String(value)).format('DD/MM')
    }
    return '';
})

Vue.filter('f_date_time', function (value) {
    if (value) {
        try {
            value = value.replace('T', ' ').split('.')[0];
        } catch (e) {
        }
        return moment(String(value)).format('DD/MM/YYYY HH:mm')
    }
    return '';
})

Vue.filter('f_time', function (value) {
    if (value) {
        try {
            value = value.replace('T', ' ').split('.')[0];
        } catch (e) {
        }
        return moment(String(value)).format('HH:mm')
    }
    return '';
})
Vue.filter('bk20', function (value) {
    try {
        if (value.length > 20)
            return value.substring(0, 20) + '...';

    } catch (e) {
    }
    return value;
})

Vue.filter('bk25', function (value) {
    try {
        if (value.length > 25)
            return value.substring(0, 25) + '...';
    } catch (e) {
    }
    return value;
})

Vue.filter('bk50', function (value) {
    try {
        if (value.length > 50)
            return value.substring(0, 50) + '...';
    } catch (e) {
    }
    return value;
})

Vue.filter('bk80', function (value) {
    try {
        if (value.length > 80)
            return value.substring(0, 80) + '...';
    } catch (e) {
    }
    return value;
})

Vue.filter('bk100', function (value) {
    try {
        if (value.length > 100)
            return value.substring(0, 100) + '...';
    } catch (e) {
    }
    return value;
})


Vue.filter('bk200', function (value) {
    try {
        if (value.length > 200)
            return value.substring(0, 200) + '...';
    } catch (e) {
    }
    return value;
})

Vue.config.devtools = false;
Vue.config.productionTip = false; // Desabilitar o aviso de desenvolvimento


require("@/assets/icons/iconly/index.min.css");
require("@/assets/icons/remix-icon/index.min.css");
require("@/assets/scss/app.scss");

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
