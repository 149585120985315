const labels = {
    title: 'Classificação de calendário',
    plural: 'Classificações de calendário',
    upper: 'CLASSIFICAÇÃO DE CALENDÁRIO',
    upperPlural: 'CLASSIFICAÇÕES DE CALENDÁRIO',
    lower: 'classificação de calendário',
    lowerPlural: 'classificações de calendário'
}
export default {
    title: labels.title,
    plural: labels.plural,
    upper: labels.upper,
    upperPlural: labels.upperPlural,
    lower: labels.lower,
    lowerPlural: labels.lowerPlural,
    headers: {

        id: {
            label: "ID",
            key: "id",
        },
        label: {
            label: "Nome",
            key: "name",
        },
        description: {
            label: "Identificador",
            key: "identifier",
        },
        color: {
            label: "Cor",
            key: "cor",
        },
        // icon
        icon: {
            label: "Ícone",
            key: "icon",
        },
        is_school: {
            label: "É uma classificação da escola ou geral",
            key: "is_school",
        },
        is_user: {
            label: "Mostrar para todos ou só para o usuário relacionado",
            key: "is_user",
        },
        type: {
            label: "Tipo",
            key: "type",
        },

        created_at: {
            label: "Data de criação",
            key: "created_at",
        },
        updated_at: {
            label: "Última atualização",
            key: "updated_at",
        },
    },
    erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
    erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
    itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
    itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
    itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
    itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
    itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
    itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
    createTitle: `Nova ${labels.lower}`,
    create: `${labels.title} criado com sucesso.`,
    update: `${labels.title} editado com sucesso.`,
    updateTitle: `Editar ${labels.lower} disciplina`,
    createError: `Erro ao criar o ${labels.lower}.`,
    updateError: `Erro ao editar a ${labels.lower}.`,
    inputs: {
        id: null,
        label: null,
        description: null,
        color: null,
        icon: null,
        is_school: 0,
        is_user: 0,
    },
    inputsInfos: {
        id: {
            label: "ID",
            description: `ID do ${labels.lower}`,
            example: "1",
        },
        label: {
            label: `Nome do ${labels.lower}`,
            description: `Nome do ${labels.lower}`,
            example: "Ensino Fundamental 1",
        },
        description: {
            label: "Descrição",
            description: `Descrição do ${labels.lower}`,
            example: "EF1",
        },
        color: {
            label: "Cor",
            description: `Cor do ${labels.lower}`,
            example: "#000000",
        },
        icon: {
            label: "Ícone",
            description: `Ícone do ${labels.lower}`,
            example: "ri-dashboard-line",
        },
        is_school: {
            label: "É uma classificação da escola ou geral",
            description: `Exemplo: Feriados (geral), Aulas (escolar), Atividades (escolar)`,
            example: "1",
        },
        is_user: {
            label: "Mostrar para todos ou só para o usuário relacionado",
            description: `Professores podem cadastrar eventos para aparecer somente para eles`,
            example: "1",
        },
        type: {
            label: "Tipo",
            description: `Tipo do ${labels.lower}`,
            example: "GERAL",
            options: [
                {value: "GERAL", text: "Geral"},
                {value: "AULA", text: "Aula"},
                {value: "PROVA", text: "Prova"},
            ]
        },
        created_at: {
            label: "Data de criação",
            description: `Data de criação do ${labels.lower}`,
            example: "2021-01-01 00:00:00",
        },
        updated_at: {
            label: "Última atualização",
            description: `Data da última atualização do ${labels.lower}`,
            example: "2021-01-01 00:00:00",
        }
    }
}
