import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;
const name = "warnings";

const axiosInstance = Axios.create({
  baseURL: domain + "/v1_atendimento",
});

export const warningsFetch = async (id_school) => {
  try {
    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const warningsCreate = async (id_school, payload) => {
  try {
    const formData = new FormData();
    formData.append("id", payload.id);
    formData.append("label", payload.label);
    formData.append("image", payload.image);
    formData.append("video", payload.video);
    formData.append("audio", payload.audio);
    formData.append("link", payload.link);
    formData.append("status", payload.status);
    formData.append("send", payload.send);
    formData.append("aproval", payload.aproval);
    formData.append("peoples", JSON.stringify( payload.peoples.map((el) => el.id)));
    formData.append("classes", JSON.stringify( payload.classes.map((el) => el.id)));
    formData.append("peoples_types", JSON.stringify( payload.peoples_types.map((el) => el.id)));
    const response = await axiosInstance({
      method: "post",
      url: `v1/${id_school}/${name}/create`,
      data: formData,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const warningsUpdate = async (id_school, payload) => {
  try {
    const formData = new FormData();
    formData.append("id", payload.id);
    formData.append("label", payload.label);
    formData.append("image", payload.image);
    formData.append("video", payload.video);
    formData.append("audio", payload.audio);
    formData.append("link", payload.link);
    formData.append("status", payload.status);
    formData.append("send", payload.send);
    formData.append("aproval", payload.aproval);
    formData.append("peoples", JSON.stringify( payload.peoples.map((el) => el.id)));
    formData.append("classes", JSON.stringify( payload.classes.map((el) => el.id)));
    formData.append("peoples_types", JSON.stringify( payload.peoples_types.map((el) => el.id)));
    const response = await axiosInstance({
      method: "put",
      url: `v1/${id_school}/${name}/update/${payload.id}`,
      data: formData,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const warningsDeletex = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "delete",
      url: `v1/${id_school}/${name}/delete/${payload.id}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const warningsPaginate = async (id_school, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;

    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}/paginate`,
      params: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const warningsRemove = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "delete",
      url: `v1/${id_school}/${name}/remove/${payload.id}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const warningsTrash = async (id_school, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;
    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}/trash`,
      params: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const warningsRestore = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "put",
      url: `v1/${id_school}/${name}/restore/${payload.id}`,
      data: {id: payload.id},
    });
    return response;
  } catch (error) {
    return error;
  }
};
